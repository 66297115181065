
.navbar-white {
    height: 72px;
    background-color: $white;
    -webkit-transition: $motion-enter;
    -moz-transition: $motion-enter;
    -o-transition: $motion-enter;
    transition: $motion-enter;
    .navbar-brand {
      color: $text-color;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 1.1px;
      margin-top:5px;
      small {
        letter-spacing: normal;
        font-family: $font-mono;
        font-size: 13px;
        color: $navbar-light-disabled-color;
        font-weight: 400;
        border-left: $navbar-light-disabled-color 1px solid;
        padding: 3px 12px;
        margin-left: 6px;
      }
      @include hover-focus {
        color: $navbar-light-brand-hover-color;
      }
    }
  
    .navbar-nav {
      .nav-link {
        color: $text-color;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 300;
        letter-spacing: 1.2px;
        margin-left: 10px;
        margin-top:4px;
        @include hover-focus {
          color: $navbar-white-hover-color;
        }
  
        &.disabled {
          color: $navbar-light-disabled-color;
        }
      }
  
      .show > .nav-link,
      .active > .nav-link,
      .nav-link.show,
      .nav-link.active {
        color: $black;
      }
    }
  
    .navbar-toggler {
      color: $navbar-light-color;
      border: none;
    }
  
    .navbar-toggler-icon {
      background-image: $navbar-light-toggler-icon-bg;
    }
  
    .navbar-text {
      color: $black;
      a {
        color: $black;
  
        @include hover-focus {
          color: $navbar-white-active-color;
        }
      }
    }
  }
  
  .navbar-default {
    height: 140px;
    -webkit-transition: $motion-leave;
    -moz-transition: $motion-leave;
    -o-transition: $motion-leave;
    transition: $motion-leave;
    .navbar-brand {
      color: $text-color;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 1.1px;
      margin-top:5px;
      small {
        letter-spacing: normal;
        font-family: $font-mono;
        font-size: 13px;
        color: $navbar-light-disabled-color;
        font-weight: 400;
        border-left: $navbar-light-disabled-color 1px solid;
        padding: 3px 12px;
        margin-left: 6px;
      }
      @include hover-focus {
        color: $navbar-light-brand-hover-color;
      }
    }
  
    .navbar-nav {
      .nav-link {
        color: $text-color;
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 300;
        letter-spacing: 1.2px;
        margin-left: 10px;
        margin-top:4px;
        @include hover-focus {
          color: $navbar-white-hover-color;
        }
  
        &.disabled {
          color: $navbar-light-disabled-color;
        }
      }
  
      .show > .nav-link,
      .active > .nav-link,
      .nav-link.show,
      .nav-link.active {
        color: $black;
      }
    }
  
    .navbar-toggler {
      color: $navbar-light-color;
      border: none;
    }
  
    .navbar-toggler-icon {
      background-image: $navbar-light-toggler-icon-bg;
    }
  
    .navbar-text {
      color: $black;
      a {
        color: $black;
  
        @include hover-focus {
          color: $navbar-white-active-color;
        }
      }
    }
  }