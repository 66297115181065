.animated {
  animation-fill-mode: both;
}

.animated.slow {
  animation-duration: 2s;
}

.animated.slower{
  animation-duration: 4s;
}

.animated.fast {
  animation-duration: .5s
}

.animated.faster {
  animation-duration: .3s
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}
