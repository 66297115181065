

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  src: local("IBM Plex Mono Bold"), local("IBMPlexMono-Bold"), url("vendor/fonts/ibm/IBMPlexMono-Bold.woff2") format("woff2"), url("vendor/fonts/ibm/IBMPlexMono-Bold.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  src: local("IBM Plex Mono Bold"), local("IBMPlexMono-Bold"), url("vendor/fonts/ibm/IBMPlexMono-Bold-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  src: local("IBM Plex Mono Bold"), local("IBMPlexMono-Bold"), url("vendor/fonts/ibm/IBMPlexMono-Bold-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  src: local("IBM Plex Mono Bold"), local("IBMPlexMono-Bold"), url("vendor/fonts/ibm/IBMPlexMono-Bold-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-display: auto;
  font-weight: 400;
  src: local("IBM Plex Mono Italic"), local("IBMPlexMono-Italic"), url("vendor/fonts/ibm/IBMPlexMono-Italic.woff2") format("woff2"), url("vendor/fonts/ibm/IBMPlexMono-Italic.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-display: auto;
  font-weight: 400;
  src: local("IBM Plex Mono Italic"), local("IBMPlexMono-Italic"), url("vendor/fonts/ibm/IBMPlexMono-Italic-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-display: auto;
  font-weight: 400;
  src: local("IBM Plex Mono Italic"), local("IBMPlexMono-Italic"), url("vendor/fonts/ibm/IBMPlexMono-Italic-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-display: auto;
  font-weight: 400;
  src: local("IBM Plex Mono Italic"), local("IBMPlexMono-Italic"), url("vendor/fonts/ibm/IBMPlexMono-Italic-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-display: auto;
  font-weight: 400;
  src: local("IBM Plex Mono"), local("IBMPlexMono"), url("vendor/fonts/ibm/IBMPlexMono-Regular.woff2") format("woff2"), url("vendor/fonts/ibm/IBMPlexMono-Regular.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: local("IBM Plex Mono"), local("IBMPlexMono"), url("vendor/fonts/ibm/IBMPlexMono-Regular-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: local("IBM Plex Mono"), local("IBMPlexMono"), url("vendor/fonts/ibm/IBMPlexMono-Regular-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: local("IBM Plex Mono"), local("IBMPlexMono"), url("vendor/fonts/ibm/IBMPlexMono-Regular-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"), url("vendor/fonts/ibm/IBMPlexSans-Bold.woff2") format("woff2"), url("vendor/fonts/ibm/IBMPlexSans-Bold.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"), url("vendor/fonts/ibm/IBMPlexSans-Bold-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"), url("vendor/fonts/ibm/IBMPlexSans-Bold-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-display: auto;
  src: local("IBM Plex Sans Bold"), local("IBMPlexSans-Bold"), url("vendor/fonts/ibm/IBMPlexSans-Bold-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-display: auto;
  font-weight: 300;
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url("vendor/fonts/ibm/IBMPlexSans-Light.woff2") format("woff2"), url("vendor/fonts/ibm/IBMPlexSans-Light.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-display: auto;
  font-weight: 300;
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url("vendor/fonts/ibm/IBMPlexSans-Light-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-display: auto;
  font-weight: 300;
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url("vendor/fonts/ibm/IBMPlexSans-Light-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-display: auto;
  font-weight: 300;
  src: local("IBM Plex Sans Light"), local("IBMPlexSans-Light"), url("vendor/fonts/ibm/IBMPlexSans-Light-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-display: auto;
  font-weight: 400;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url("vendor/fonts/ibm/IBMPlexSans-Regular.woff2") format("woff2"), url("vendor/fonts/ibm/IBMPlexSans-Regular.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-display: auto;
  font-weight: 400;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url("vendor/fonts/ibm/IBMPlexSans-Regular-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-display: auto;
  font-weight: 400;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url("vendor/fonts/ibm/IBMPlexSans-Regular-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-display: auto;
  font-weight: 400;
  src: local("IBM Plex Sans"), local("IBMPlexSans"), url("vendor/fonts/ibm/IBMPlexSans-Regular-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-display: auto;
  font-weight: 400;
  src: local("IBM Plex Serif Italic"), local("IBMPlexSerif-Italic"), url("vendor/fonts/ibm/IBMPlexSerif-Italic.woff2") format("woff2"), url("vendor/fonts/ibm/IBMPlexSerif-Italic.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-display: auto;
  font-weight: 400;
  src: local("IBM Plex Serif Italic"), local("IBMPlexSerif-Italic"), url("vendor/fonts/ibm/IBMPlexSerif-Italic-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-display: auto;
  font-weight: 400;
  src: local("IBM Plex Serif Italic"), local("IBMPlexSerif-Italic"), url("vendor/fonts/ibm/IBMPlexSerif-Italic-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-display: auto;
  font-weight: 400;
  src: local("IBM Plex Serif Italic"), local("IBMPlexSerif-Italic"), url("vendor/fonts/ibm/IBMPlexSerif-Italic-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-display: auto;
  font-weight: 300;
  src: local("IBM Plex Serif Light"), local("IBMPlexSerif-Light"), url("vendor/fonts/ibm/IBMPlexSerif-Light.woff2") format("woff2"), url("vendor/fonts/ibm/IBMPlexSerif-Light.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-display: auto;
  font-weight: 300;
  src: local("IBM Plex Serif Light"), local("IBMPlexSerif-Light"), url("vendor/fonts/ibm/IBMPlexSerif-Light-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-display: auto;
  font-weight: 300;
  src: local("IBM Plex Serif Light"), local("IBMPlexSerif-Light"), url("vendor/fonts/ibm/IBMPlexSerif-Light-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-display: auto;
  font-weight: 300;
  src: local("IBM Plex Serif Light"), local("IBMPlexSerif-Light"), url("vendor/fonts/ibm/IBMPlexSerif-Light-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: italic;
  font-display: auto;
  font-weight: 300;
  src: local("IBM Plex Serif Light Italic"), local("IBMPlexSerif-LightItalic"), url("vendor/fonts/ibm/IBMPlexSerif-LightItalic.woff2") format("woff2"), url("vendor/fonts/ibm/IBMPlexSerif-LightItalic.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-display: auto;
  font-weight: 500;
  src: local("IBM Plex Serif Medium"), local("IBMPlexSerif-Medium"), url("vendor/fonts/ibm/IBMPlexSerif-Medium.woff2") format("woff2"), url("vendor/fonts/ibm/IBMPlexSerif-Medium.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-display: auto;
  font-weight: 500;
  src: local("IBM Plex Serif Medium"), local("IBMPlexSerif-Medium"), url("vendor/fonts/ibm/IBMPlexSerif-Medium-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-display: auto;
  font-weight: 500;
  src: local("IBM Plex Serif Medium"), local("IBMPlexSerif-Medium"), url("vendor/fonts/ibm/IBMPlexSerif-Medium-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-display: auto;
  font-weight: 500;
  src: local("IBM Plex Serif Medium"), local("IBMPlexSerif-Medium"), url("vendor/fonts/ibm/IBMPlexSerif-Medium-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-display: auto;
  font-weight: 400;
  src: local("IBM Plex Serif"), local("IBMPlexSerif"), url("vendor/fonts/ibm/IBMPlexSerif-Regular.woff2") format("woff2"), url("vendor/fonts/ibm/IBMPlexSerif-Regular.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-display: auto;
  font-weight: 400;
  src: local("IBM Plex Serif"), local("IBMPlexSerif"), url("vendor/fonts/ibm/IBMPlexSerif-Regular-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-display: auto;
  font-weight: 400;
  src: local("IBM Plex Serif"), local("IBMPlexSerif"), url("vendor/fonts/ibm/IBMPlexSerif-Regular-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-display: auto;
  font-weight: 400;
  src: local("IBM Plex Serif"), local("IBMPlexSerif"), url("vendor/fonts/ibm/IBMPlexSerif-Regular-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-display: auto;
  font-weight: 600;
  src: local("IBM Plex Serif SemiBold"), local("IBMPlexSerif-SemiBold"), url("vendor/fonts/ibm/IBMPlexSerif-SemiBold.woff2") format("woff2"), url("vendor/fonts/ibm/IBMPlexSerif-SemiBold.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-display: auto;
  font-weight: 600;
  src: local("IBM Plex Serif SemiBold"), local("IBMPlexSerif-SemiBold"), url("vendor/fonts/ibm/IBMPlexSerif-SemiBold-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-display: auto;
  font-weight: 600;
  src: local("IBM Plex Serif SemiBold"), local("IBMPlexSerif-SemiBold"), url("vendor/fonts/ibm/IBMPlexSerif-SemiBold-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-display: auto;
  font-weight: 600;
  src: local("IBM Plex Serif SemiBold"), local("IBMPlexSerif-SemiBold"), url("vendor/fonts/ibm/IBMPlexSerif-SemiBold-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-display: auto;
  font-weight: 450;
  src: local("IBM Plex Serif Text"), local("IBMPlexSerif-Text"), url("vendor/fonts/ibm/IBMPlexSerif-Text.woff2") format("woff2"), url("vendor/fonts/ibm/IBMPlexSerif-Text.woff") format("woff"); }

@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-display: auto;
  font-weight: 450;
  src: local("IBM Plex Serif Text"), local("IBMPlexSerif-Text"), url("vendor/fonts/ibm/IBMPlexSerif-Text-Latin3.woff2") format("woff2");
  unicode-range: U+0102-0103, U+1EA0-1EF9, U+20AB; }
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-display: auto;
  font-weight: 450;
  src: local("IBM Plex Serif Text"), local("IBMPlexSerif-Text"), url("vendor/fonts/ibm/IBMPlexSerif-Text-Latin2.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF, U+FB01-FB02; }
@font-face {
  font-family: 'IBM Plex Serif';
  font-style: normal;
  font-display: auto;
  font-weight: 450;
  src: local("IBM Plex Serif Text"), local("IBMPlexSerif-Text"), url("vendor/fonts/ibm/IBMPlexSerif-Text-Latin1.woff2") format("woff2");
  unicode-range: U+0000, U+000D, U+0020-007E, U+00A0-00A3, U+00A4-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2039-203A, U+2044, U+2074, U+20AC, U+2122, U+2212, U+FB01-FB02; }
