@import "variables";
@import "bootstrap-fs";

@import "navbar-fs";
@import "syntax-highlighting";
@import "animations";
@import "ionicons";
@import "fonts"; //ionicons, ibm plex

body {
    animation: fadeSiteIn 50ms cubic-bezier(0.2, 0.2, 0.38, 0.9) forwards; /* [1] */
}

body.fadeSiteOut {
  animation:  fadeSiteOut 50ms cubic-bezier(0.2, 0.2, 0.38, 0.9) forwards; /* [2] */
}


@keyframes fadeSiteIn { /* [1] */
    from   { opacity: 0; }
    to    { opacity: 1; }
}

@keyframes fadeSiteOut { /* [2] */
    from   { opacity: 1; }
    to     { opacity: 0; }
}

body {
  font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-blue {
  color: $blue;
}

.red-border {
  border: red 1px solid;
}

.font-serif {
  font-family: $font-serif;
}

.font-mono {
  font-family: $font-mono;
}

.font-sans {
  font-family: $font-sans;
}

blockquote {
  background-color: #fff8e7;
  padding: 30px 20px 15px;
  font-size: smaller;
  font-family: $font-sans;
  color: #555;
  border-left: #c0c0c0 6px solid;
}

#content {
  margin-top: 156px;
}

.bg-light-blue {
  background-color: $light-blue-bg;
}

.text-black {
  color: #111;
}

#about {
  margin-top: 70px;
  p {
    font-size: 22px;
    font-weight: $font-weight-light;
    letter-spacing: .9px;
    line-height: 28px;
  }
}

#workflow {
  .ion {
    font-size: 72px;
  }
  h5 {
    font-weight: $font-weight-light;
    margin-top: 25px;
  }
}

.shadowed {
  -webkit-box-shadow: 0 2px 30px 0 rgba(0,0,0,.1);
  box-shadow: 0 2px 30px 0 rgba(0,0,0,.1);
}

.post-thumbnail {
  color: black;
  margin-top: 30px;
  background-color: $white;
  & .caption {
    padding: 20px;
    overflow-wrap: break-word;
    & .post-caption {
      a {
        color: map-get($grays, "900");
        font-size: 14pt;
        font-weight: $font-weight-bold;
      }
    }
    & p {
      font-family: $font-serif;
    }
  }
}

#clients, #posts, #contact {
  padding-top: 100px;
  padding-bottom: 80px;
  & h1 {
    font-size: 22pt;
    font-weight: $font-weight-bold;
    letter-spacing: 1.5px;
    line-height: 1.8em;
    & :after {
      content:' ';
      display:block;
      border-top: 1px solid map-get($grays, "600");
      width: 40px;
    }
  }
  & h2 {
    font-size: 16pt;
    font-weight: $font-weight-light;
    color: map-get($grays, "700");
    line-height: 1.5em;
  }
}

#clients {
  padding: 120px 0px;
  & .lessOpacity {
    opacity: 0.6;
    filter: alpha(opacity=60);
    zoom: 1;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    &.card {
      background: transparent;
      border: none;
      cursor: pointer;
    }
  }
  & .lessOpacity:hover {
    opacity: 1;
    filter: alpha(opacity=100);
    zoom: 1;
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
    background-color: map-get($grays, "200");
  }
}

.settings-box {
  display: none;
  height: 100%;
  min-width: 100%;
  background-color: $white;
  position: absolute;
  width: 100%;
  z-index: 999;
  top: -1px;
  left: 0px;
  padding: 18px;
  opacity: .95;
  border: map-get($grays, "200") 1px solid;
  overflow-x: hidden;
  overflow-y: auto;
  font-weight: $font-weight-light;
  & h3 {
    font-weight: $font-weight-bold;
    font-size: 12pt;
  }
}

.settings-icon {
  float: right;
  cursor: pointer;
  & a {
    color: #aaa;
    font-size: 18pt;
    line-height: 5px;
  }
  & a:hover {
    color: #69b6e6;
  }
}

.bg-light-gray {
  background-color: $light-grey-bg;
}


#contact {
  background-color: map-get($grays, "700");
  color: map-get($grays, "200");
  padding: 75px 0px;
  font-weight: $font-weight-light;
  line-height: 1.5em;
  a {
    color: map-get($grays, "100");
  }
  .ion {
    margin-right: 10px;
    font-size: 1.2em;
  }
  h3.lets-talk {
    font-size: 32pt;
    font-weight: $font-weight-light;
    line-height: 10pt;
  }
  h2 {
    color: map-get($grays, "200");
    font-size:12pt;
  }

  .form-input {
    width: 100%;

    label {
      position:relative;
      display:block;
      width:100%;
      min-height:60px;
    }

    .placeholder {
      position:absolute;
      display:block;
      top:4px;
      left:4px;
      z-index:2;
      font-size:16px;
      transition: all 110ms cubic-bezier(0.2, 0.2, 0.38, 0.9);
      width: 100%;
      color: map-get($grays, "100");
      cursor: text;
    }

    input, textarea {
      background: transparent;
      position:absolute;
      top:0px;
      z-index:1;
      width:100%;
      border: none;
      border-bottom: 1px solid map-get($grays, "500");
      transition: border-color 110ms cubic-bezier(0.2, 0.2, 0.38, 0.9);
      outline:none;
      padding: 5px 4px;
      margin: 0;
      color: map-get($grays, "200");
    }

    textarea {
      resize: none;
    }

    input {

    }

    input:focus,
    input:valid,
    textarea:focus,
    textarea:valid {
      & + .placeholder {
        top:-25px;
        cursor:inherit;
        font-size:14px;
        color: map-get($grays, "400");
      }
      background-color: map-get($grays, "600");
      border-bottom: 2px solid map-get($grays, "100");
    }
  }

  .alert {
    border-radius: 0px;
    border: none;
    padding: 6px;
    &.alert-danger {
      border-bottom: #a00 2px solid;
    }
    &.alert-success {
      border-bottom: #0a0 2px solid;
    }
  }
}

pre {
  border: map-get($grays, "400") 1px solid;
  border-radius: 4px;
  background-color: map-get($grays, "100");
  border-left: map-get($grays, "700") 4px solid;
  margin: 0px;
}

h1.post-title {
  font-weight: $font-weight-bold;
}

.rindicator {
    height: 2px;
    background-color: #FF643B;
    width: 0px;
    position: fixed;
    top: 0px;
    z-index: 99999;
    left: 0;
}


.post-content {
  min-width: 15em;
	max-width: 38em;
  margin: 0px auto;
  font-size: 15pt;
  font-family: $font-serif;
  & h2, h3, h4, h5 {
    font-size: larger;
    font-weight: $font-weight-bold;
    font-family: $font-sans;
    margin-top: 40px;
    margin-bottom: 12px;
  }
}
