@charset "UTF-8";

@font-face {
  font-family: "Ionicons";
  src: url("vendor/fonts/Ionicons/ionicons.eot?v=2.0.0");
  src: url("vendor/fonts/Ionicons/ionicons.eot?v=2.0.0#iefix") format("embedded-opentype"), url("vendor/fonts/Ionicons/ionicons.ttf?v=2.0.0") format("truetype"), url("vendor/fonts/Ionicons/ionicons.woff?v=2.0.0") format("woff"), url("vendor/fonts/Ionicons/ionicons.svg?v=2.0.0#Ionicons") format("svg");
  font-weight: normal;
  font-style: normal;
}

.ion, .ionicons,
.ion-navicon:before,
.ion-ios-close-empty:before,
.ion-ios-lightbulb-outline:before,
.ion-ios-compose-outline:before,
.ion-ios-crop:before,
.ion-ios-monitor-outline:before,
.ion-ios-checkmark-empty:before,
.ion-ios-reload:before,
.ion-social-github:before,
.ion-social-twitter:before,
.ion-social-linkedin:before,
.ion-social-googleplus-outline:before,
.ion-social-instagram-outline:before,
.ion-social-rss:before,
.ion-ios-telephone-outline:before,
.ion-ios-email-outline:before,
.ion-ios-location-outline:before,
.ion-ios-cloud-download-outline:before,
.ion-ios-circle-outline:before,
.ion-ios-close-empty:before
{
  display: inline-block;
  font-family: "Ionicons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  text-rendering: auto;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.ion-navicon:before {
  content: "\f20e";
}

.ion-ios-lightbulb-outline:before {
  content: "\f451";
}

.ion-ios-compose-outline:before {
  content: "\f417";
}

.ion-ios-crop:before {
  content: "\f41e";
}

.ion-ios-monitor-outline:before {
  content: "\f465";
}

.ion-ios-checkmark-empty:before {
  content: "\f3fd";
}

.ion-ios-reload:before {
  content: "\f49d";
}

.ion-social-github:before {
  content: "\f233";
}

.ion-social-twitter:before {
  content: "\f243";
}

.ion-social-linkedin:before {
  content: "\f239";
}

.ion-social-googleplus-outline:before {
  content: "\f234";
}

.ion-social-instagram-outline:before {
  content: "\f350";
}

.ion-social-rss:before {
  content: "\f23d";
}

.ion-ios-close-empty:before {
  content: "\f404";
}

.ion-ios-telephone-outline:before {
  content: "\f4b8";
  font-size: 16pt;
}

.ion-ios-email-outline:before {
  content: "\f422";
  font-size: 16pt !important;
  padding-top: 1px;
}

.ion-ios-location-outline:before {
  content: "\f455";
  font-size: 14pt;
}

.ion-ios-monitor-outline.small {
  font-size: 14pt !important;
}

.ion-ios-cloud-download-outline:before {
  content: "\f407";
}

.ion-ios-circle-outline:before {
  content: "\f401";
}

.ion-ios-close-empty:before {
    content: "\f404";
}
