$white:    #fff;
$blue:     #2255ff;
$black: #121314;
$text-color: #1f1d1f;

$navbar-white-active-color: $blue;
$navbar-white-hover-color: $blue;
$primary: $blue;

/* custom vars */
$custom-font-size: 20px;

$light-grey-bg: #f2f5f7;
$light-blue-bg: #e7f0f9;
$body-bg: $white;
$body-color: $text-color;

$font-sans: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;
$font-serif: 'IBM Plex Serif', 'Georgia', Times, serif;
$font-mono: 'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', Courier, monospace;
$font-family-monospace: $font-mono;

// font-size: 14px, line-height: 24px, charsPerLine: 60
// https://medium.com/@zkareemz/golden-ratio-62b3b6d4282a
$post-line-width: calc(60 * (14 / 1.618))px;

$motion-leave: all 108ms cubic-bezier(0.2, 0.2, 0.38, 0.9);
$motion-enter: all 128ms cubic-bezier(0.4, 0.14, 0.3, 1);

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 700;
